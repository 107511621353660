import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../../components/layout"

export default class News extends React.Component {

  renderNews() {
    return null
  }

  render() {
    const { news } = this.props.data
    return (
      <Layout lang="en">
        <Helmet>
          <title>{ news.title_en } — Catenaccio</title>
        </Helmet>

        <section className="container news-article-header">
          <div className="row">
            <div className="col-xl-12">
              <h1>{ news.title_en }{ news.subtitle_en && '.'} <span>{ news.subtitle_en }</span></h1>
              <p className="news-article-meta">{ news.date_en }</p>
              <img src={ news.image.childImageSharp.fluid.src } width="100%" />
            </div>
          </div>
        </section>

        <article>
          <section className="container text-section">{ this.renderNews() }</section>
        </article>
      </Layout>
    )
  }
}

export const query = graphql`
fragment NewsInfoEn on NewsYaml {
  title_en
  subtitle_en
  date_en
  image {
    childImageSharp {
      fluid(maxWidth: 1680) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`