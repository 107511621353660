import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "sorokin_prolongs_till_2023" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Vladislavs Sorokins, a 24 year-old player of the Latvian National team, is prolonging his contract with FK RFS for two more years.</p>
          <p>Sorokins played 121 matches for FC RFS and is a record holder of the club.</p>
          <p>Catenaccio Sports Agency wishes Vladislavs to go even further and cross the mark of 150 matches as soon as possible! 💪💪💪</p>
        </div>
      </div>
    )
  }
}