import { Link } from "gatsby"
import React from "react"

export default class Header extends React.Component {

  state = {
    mobileOpened: false
  }

  toggleHamburger = () => {
    if (this.state.mobileOpened) {
      this.setState({ mobileOpened: false });
      return;
    }
    this.setState({ mobileOpened: true });
  }

  get links() {
    return [
      { to: '/', label: 'Catenaccio' },
      { to: '/#news', label: 'Новости' },
      { to: '/#players', label: 'Игроки' },
      { to: '/#transfers', label: 'Трансферы' },
      { to: '/#law', label: 'Спортивное право' },
      { to: '/#services', label: 'Услуги', mobile_to: '/#services-mobile' },
      { to: '/#contacts', label: 'Контакты' },
    ];
  }

  get linksEn() {
    return [
      { to: '/en/', label: 'Catenaccio' },
      { to: '/en/#news', label: 'News' },
      { to: '/en/#players', label: 'Players' },
      { to: '/en/#transfers', label: 'Transfers' },
      { to: '/en/#law', label: 'Legal' },
      { to: '/en/#services', label: 'Services', mobile_to: '/#services-mobile' },
      { to: '/en/#contacts', label: 'Contact us' },
    ];
  }

  scrollTo = (e, href, mobile) => {
    if (!this.props.smoothScroll && href.includes('/')) return;
    if (href === '/') {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      if (mobile) this.toggleHamburger();
      e.preventDefault();
      return;
    };
    const scrollId = href.replace('en/', '').replace(/\/|#/g, '')
    const elemToScroll = document.getElementById(scrollId);
    if (mobile) this.toggleHamburger();
    elemToScroll.scrollIntoView({ behavior: 'smooth' });
    e.preventDefault();
  }

  renderLink = (link, mobile) => {
    const href = mobile && link.mobile_to ? link.mobile_to : link.to;
    return (
      <li key={ link.label }>
        <Link to={ href } className={ !mobile ? 'hover-underline' : '' } onClick={ (e) => this.scrollTo(e, href, mobile) }>{ link.label }</Link>
      </li>
    );
  }

  renderSocialLinks() {
    if (!this.props.showSocial) return;
    return <aside className="social-header">
      <ul className="social-list">
        <li><a href="https://www.youtube.com/channel/UCiskvYcae9-d5RZUe7_uBuA" target="_blank" rel="noopener noreferrer" className="yt">Youtube</a></li>
        <li><a href="#" className="fb">Facebook</a></li>
        <li><a href="https://www.instagram.com/catenaccio_sports_agency/" target="_blank" rel="noopener noreferrer" className="ig">Instagram</a></li>
        <li><a href="#" className="tw">Twitter</a></li>
      </ul>
    </aside>
  }

  render() {
    const links = this.props.lang === 'ru' ? this.links : this.linksEn
    return (
      <React.Fragment>
        <header>
          <nav>
            <ul>
              { links.map( link => this.renderLink(link, false) ) }
            </ul>
            <ul className="language">
              <li className={ this.props.lang === 'en' ? "active" : '' }>
                <Link to="/en" className="hover-underline">EN</Link>
              </li>
              <li className={ this.props.lang !== 'en' ? "active" : '' }>
                <Link to="/" className="hover-underline">RU</Link>
              </li>
            </ul>
          </nav>
        </header>
        { this.renderSocialLinks() }

        {/* MOBILE HEADER */}
        <header className="mobile-header">
          <ul className="social-list">
            <li>
              <a href="#" className="fb">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/catenaccio_sports_agency" className="ig">
                Instagram
              </a>
            </li>
            <li>
              <a href="#" className="tw">
                Twitter
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCiskvYcae9-d5RZUe7_uBuA" target="_blank" rel="noopener noreferrer" className="yt">
                Youtube
              </a>
            </li>
          </ul>
          <label className="menu-button">
            <div className={ `hamburger ${ this.state.mobileOpened ? 'active' : '' }` } onClick={ this.toggleHamburger }>
              <div className="stick" />
              <div className="stick" />
              <div className="stick" />
            </div>
          </label>
        </header>
        <nav className={ `mobile-nav ${ this.state.mobileOpened ? 'showed' : '' }` }>
          <ul>
            { links.map( link => this.renderLink(link, true) ) }
          </ul>
          <ul className="language">
            <li className={ this.props.lang === 'en' ? "active" : '' }>
              <Link to="/en" className="hover-underline">EN</Link>
            </li>
            <li className={ this.props.lang !== 'en' ? "active" : '' }>
              <Link to="/" className="hover-underline">RU</Link>
            </li>
          </ul>
        </nav>
      </React.Fragment>
    );
  }
}
