import React from "react"

export default class Footer extends React.Component {

  render() {
    const { lang } = this.props;
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-12">
              <h2 className="section-title anchor" id="contacts">{ lang === 'ru' ? 'Контакты' : 'Contacts' }</h2>
              <address className="address-description">
                Rolna, 191/193<br/>
                { lang === 'ru' ? 'Варшава, Польша' : 'Warsaw, Poland' }
              </address>
              <p className="address-description">
                <a className="e-mail" href="mailto:info@catenaccio.pro">info@catenaccio.pro</a>
              </p>
            </div>
            <div className="col-md-6 col-12">
              <h2 className="section-title anchor">{ lang === 'ru' ? 'Следите за нами' : 'Follow us' }</h2>
              <div className="footer-logo" />
              <ul className="footer-social-list">
                <li>
                  <a href="https://www.youtube.com/channel/UCiskvYcae9-d5RZUe7_uBuA" className="yt">
                    Youtube
                  </a>
                </li>
                <li>
                  <a href="#" className="fb">
                    Facebook
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/catenaccio_sports_agency/" className="ig">
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="#" className="tw">
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 copyright">© Catenaccio SRL, 2019</div>
          </div>
        </div>
      </footer>
    );
  }
}